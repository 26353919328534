.MainContainer {
  background: #ffffff;
  border: 0.5px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  padding: 17px 10px 10px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5d5d5d;
    margin-bottom: 4px;
  }

  hr {
    border: none;
    border-top: 1px solid #f6f6f6;
  }

  .container {
    padding: 10px;

    .filters {
      padding-bottom: 10px;
      .ant-input-search {
        width: 350px;
        input {
          font-size: 12px;
        }
        .ant-input-suffix {
          i {
            color: #fff;
          }
        }
        .anticon-close-circle {
          padding-left: 5px;
        }
      }
    }

    .clients-table {
      .ant-table-row {
        cursor: pointer;
      }
      .ant-tag-green {
        background-color: #fff;
        padding: 0 15px;
        border: 1px solid #26ac00;
        color: #26ac00;
        font-weight: 600;
      }
      .ant-tag-orange {
        margin-top: 5px;
        background-color: #fff;
        padding: 0 15px;
        border: 1px solid #ff7b00;
        color: #ff7b00;
        font-weight: 600;
      }
      .ant-tag-red {
        background-color: #fff;
        padding: 0 15px;
        border: 1px solid #e53d2e;
        color: #e53d2e;
      }
    }
  }
}

.ant-popover-message > .anticon {
  color: #e53d2e;
}
.ant-popover-buttons > .ant-btn-primary {
  background-color: #e53d2e;
  border-color: #e53d2e;
}
.user-detail-modal {
  .ant-tag-green {
    background-color: #fff;
    padding: 0 15px;
    border: 1px solid #26ac00;
    color: #26ac00;
    font-weight: 600;
  }
  .ant-tag-orange {
    background-color: #fff;
    padding: 0 15px;
    border: 1px solid #ff7b00;
    color: #ff7b00;
    font-weight: 600;
  }
  .ant-tag-red {
    background-color: #fff;
    padding: 0 15px;
    border: 1px solid #e53d2e;
    color: #e53d2e;
    font-weight: 600;
  }
  .ant-modal-body {
    .upper-text {
      color: #959595 !important;
      font-size: 12px;
      font-weight: 300 !important;
      margin-bottom: 5px !important;
    }
    .cancel-edit-button {
      margin: 0 20px;
      color: #ff6b00;
      background-color: #fff;
      border: 2px solid #ff6b00;
      &:hover {
        background-color: #ff6b00;
        color: #fff;
      }
    }
    .save-edit-button {
      color: #fff;
      background-color: #ff6b00;
      border: solid 2px #ff6b00;
      &:hover {
        background-color: #fff;
        color: #ff6b00;
      }
    }
    .edit-button {
      margin: 0 20px;
      color: #959595;
      background-color: #fff;
      border: solid 2px #959595;
      &:hover {
        background-color: #959595;
        color: #fff;
      }
    }
    .block-button {
      color: #fff;
      background-color: #e53d2e;
      border: solid 1px #e53d2e;
      &:hover {
        background-color: #fff;
        color: #e53d2e;
      }
    }
    .unblock-button {
      color: #fff;
      background-color: #26ac00;
      border: solid 1px #26ac00;
      &:hover {
        background-color: #fff;
        color: #26ac00;
      }
    }
    .user-detail-card {
      .ant-card-body {
        padding: 8px 24px;
      }
      p {
        margin: 0;
      }
      .user-detail-body {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #f2f2f2;
        .user-picture {
          padding-right: 10px;
        }
        .user-data {
          .user-data-name {
            font-size: 18px;
            font-weight: 500;
          }
          .user-data-email {
            font-weight: 500;
            font-size: 13px;
          }
        }
        .user-contact-id {
          padding: 0 40px;
          p {
            font-weight: 500;
          }
        }
      }
      .user-detail-footer {
        padding: 8px 0 0;
        text-align: right;
        button {
          padding: 0 10px;
          font-size: 12px;
          font-weight: 600;
          height: 25px;
          border-radius: 7px;
          margin-left: 5px;

          span {
            text-align: center;
            i {
              font-size: 16px;
              padding-right: 5px;
            }
          }
        }
      }
    }
    .user-bootcamps-card {
      margin: 10px 0;
      .ant-card-head {
        min-height: 24px;
        border-bottom: none;
        .ant-card-head-title {
          padding: 6px 0;
          font-size: 18px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .ant-card-body {
        padding: 0px 8px 6px;
        p {
          font-weight: 600;
          text-transform: capitalize;
          margin: 0;
        }
        .ant-tabs-tab {
          text-transform: capitalize;
          font-weight: 500;
        }
        .bootcamp-detail-upper-data {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          .meal-plan {
            padding: 0 40px;
          }
        }
        .bootcamp-detail-lower-data {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          border-bottom: 1px solid #f2f2f2;
          .meal-plan,
          .user-sublevel {
            padding: 0 40px;
          }
          .assigned-coach {
            min-width: 320px;

            .ant-select {
              width: 100%;
              .ant-select-selection-selected-value {
                .ant-badge {
                  padding: 0 0 0 20px;
                }
              }
            }
          }
        }
        .bootcamp-detail-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-top: 6px;
          button {
            padding: 0 10px;
            font-size: 12px;
            font-weight: 600;
            height: 25px;
            border-radius: 7px;
            span {
              display: flex;
              align-items: center;
              justify-content: space-around;
              i {
                font-size: 16px;
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-dropdown-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .count-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .badge {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 5px 1em 10px;
      }
    }
  }
}

.ant-select {
  .ant-select-selection-selected-value {
    min-width: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
}
